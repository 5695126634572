import "./slider.scss";
interface SliderProps {
  className?: string;
  min?: number;
  max?: number;
  value: number;
  onChange: (value: number) => void;
  color?: string;
}

const Slider = ({
  value,
  onChange,
  className = "",
  min = 1,
  max = 100,
  color = "primary",
}: SliderProps) => {
  const stepNumbers = [
    Math.floor(max / 5).toString(),
    Math.floor((max / 5) * 2).toString(),
    Math.floor((max / 5) * 3).toString(),
    Math.floor((max / 5) * 4).toString(),
  ];
  return (
    <div className={"slider-container"}>
      <div className={"slider-wrapper"}>
        <input
          value={value}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          step={1}
          type="range"
          min={min}
          max={max}
          className={`slider slider-${color} ${className}`}
          id="myRange"
        />
      </div>
      <div className={"slider-step-wrapper"}></div>
    </div>
  );
};

export default Slider;
