import { useEffect } from "react";
import "./stagingIndicator.scss";

const StagingIndicator = () => {
  const isStaging = !!process.env.REACT_APP_STAGING;

  useEffect(() => {
    if (isStaging && !document.title.includes("(Staging)")) {
      document.title = `${document.title} (Staging)`;
    }
  }, [isStaging]);

  if (!isStaging) return null;

  return <div className="staging-indicator">Staging</div>;
};

export default StagingIndicator;
