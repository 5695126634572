const spacings = {
  1: "4px",
  2: "8px",
  3: "12px",
  4: "16px",
  5: "20px",
  6: "24px",
  7: "32px",
  8: "36px",
  9: "40px",
};
const colors = {
  primary: {
    1: "#E6F4FF",
    2: "#93C7D4",
    3: "#277B9D",
    4: "#08516F",
    5: "#012533",
    6: "#151516",
  },
  accent: {
    1: "#DAF4F6",
    2: "#AEE8EB",
    3: "#82DBE0",
    4: "#56CED5",
    5: "#007B83",
    6: "#004146",
  },
  error: {
    1: "#FFD7D6",
    2: "#FFA9A6",
    3: "#FF7A75",
    4: "#FF4B45",
    5: "#BE3833",
    6: "#7D2522",
  },
  success: {
    1: "#DBF1E3",
    2: "#B0E0C1",
    3: "#85D0A0",
    4: "#5ABF7E",
    5: "#438E5E",
    6: "#2C5E3E",
  },
  warning: {
    1: "#FCF9E3",
    2: "#F1E891",
    3: "#EBDD60",
    4: "#E5D32E",
    5: "#AC9E23",
    6: "#736A17",
  },
  greyScale: {
    1: "#F6F6F6",
    2: "#C9C9C9",
    3: "#9C9C9C",
    4: "#6F6F70",
    5: "#424243",
    6: "#151516",
  },
};
export { spacings, colors };
