import "./navBar.scss";
import logo from "../../../../logoBlack.png";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../library";
import { LogoHorizontal } from "../../../library/logos";
import { useState } from "react";
import { colors } from "../../../library";

const NavBar = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  return (
    <div className="nav-bar" data-open={toggle}>
      {/*<img src={logo} className="logo" />*/}
      <div className="nav-logo">
        <Link to="/" title="home" className="nav-logo-link">
          <LogoHorizontal color={colors.primary[1]} />
        </Link>
        <button className="nav-toggle" onClick={() => setToggle(!toggle)}>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
      <div className="nav-links">
        <div className="nav">
          <Link to="/">Home</Link>
          <Link to="/how-it-works">How It Works</Link>
          <Link to="/about">About</Link>
        </div>

        {/*<Button
          text="Get Started"
          className={"get-started-button"}
          onClick={() => navigate("/assessment")}
        />*/}
      </div>
    </div>
  );
};

export default NavBar;
