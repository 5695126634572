import React from "react";
import { spacings } from "../constants/";
type SpacerProps = {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  direction?: "vertical" | "horizontal";
};

const Spacer = (props: SpacerProps) => {
  const { size, direction = "vertical" } = props;
  return (
    <div
      style={{
        width: direction === "vertical" ? 0 : spacings[size],
        height: direction === "horizontal" ? 0 : spacings[size],
      }}
    />
  );
};

export default Spacer;
