import { useState, useEffect } from "react";

export const useScrollPosition = (scrollFactor = 0) => {
  const [position, setPosition] = useState(0);
  let timeout: any;
  //this function will set the value of position when the page is scrolled
  const onScroll = () => {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      // Set the scropp position
      setPosition(window.scrollY);
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    //removes the eventlistener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return position * scrollFactor;
};
