import "./howItWorksPage.scss";
import "../aboutPage/aboutPage.scss";

const HowItWorksPage = () => {
  return (
    <div className="how-it-works-page about-page">
      <div className="how-it-works-page-content about-page-content">
        <h1>How It Works</h1>
        {/* <p>
          When you create an account with Nota, you will start with an Initial
          Assessment to gauge how you feel about your identity and purpose and
          how well you’re putting them into action. Throughout your journey in
          Nota, you will retake this short assessment regularly to track your
          growth over time.
        </p> */}
        <p>
          Nota consists of interactive journeys, each focusing on specific
          topics to help you move beyond a current state and guide you closer to
          where you want to be. Each journey contains markers that unpack vital
          concepts in just a few minutes.
        </p>
        <p>
          At the end of each trail, you’ll face a challenge designed to help you
          apply what you’ve learned to your life, fostering real change over
          time. After completing a subject, you can discuss it with an AI mascot
          guide for deeper understanding or engage with your crew of friends for
          discussion. You can also customize your entire path by adding or
          removing different journeys.
        </p>
        {/* <p>
          In Nota, we provide you with the tools to answer profound questions
          like: “Who am I?”, “Why am I here?” and “What makes me unique?”
        </p> */}
      </div>
    </div>
  );
};

export default HowItWorksPage;
