import { useEffect, useState } from "react";
import { Button, Card, colors, Spacer } from "../../components/library";
import "./friends-family.scss";
import { useParams } from "react-router-dom";
import { LogoHorizontal } from "../../components/library/logos";
import useFriendsFamilySteps, {
  friendsFamilyStepType,
} from "../../hooks/friendsFamily/useFriendsFamilySteps";
import TextAreaInput, {
  TextAreaInputProps,
} from "../../components/library/inputs/textarea";
import useStepAnswers, {
  stepAnswerType,
} from "../../hooks/friendsFamily/useStepAnswers";
import MultiSelect from "../../components/library/inputs/multi-select";
import { DownloadPage } from "../index";
import { checkIfInviteLinkIsValid } from "../../api/apiClient";

type generalFriendsFamilyType = {
  addAnswer: (data: stepAnswerType) => void;
  updateStepAnswer: (stepNumber: number, data: stepAnswerType) => void;
  canGoBack: boolean;
  goBackFn: () => void;
  firstName: string;
  lastName: string;
  inviteeLastName: string;
  inviteeFirstName: string;
  stepNumber: number;
  buttonText: string;
  onSubmit: () => void;
};

type textAreaStepType = {
  stepAnswers: any;
  buttonText: string;
  stepData: {
    question: string;
    placeholder?: string;
  };
  textAreaInputProps: TextAreaInputProps;
} & generalFriendsFamilyType;

type multiSelectStepType = {
  currentStepData: friendsFamilyStepType;
  stepAnswers: any;
} & generalFriendsFamilyType;

const InvalidLinkCard = () => {
  return (
    <div className={"page assessment-step-page"}>
      <Card className={"friends-family-card"}>
        <h1>Invalid Link</h1>
        <p>The link is invalid. Please re-enter the link</p>
      </Card>
    </div>
  );
};

const AlreadyAnsweredCard = () => {
  return (
    <div className={"page assessment-step-page"}>
      <Card className={"friends-family-card"}>
        <h1>Already Answered</h1>
        <p>Thanks for your feedback. You've already answered the questions.</p>
      </Card>
    </div>
  );
};

const TextAreaStep = (props: textAreaStepType) => {
  const {
    firstName,
    lastName,
    inviteeFirstName,
    inviteeLastName,
    onSubmit,
    stepNumber,
    stepAnswers,
    updateStepAnswer,
    addAnswer,
    buttonText,
    canGoBack,
    goBackFn,
  } = props;
  const { question, placeholder } = props.stepData;
  let correctedPlaceholder = placeholder;
  if (correctedPlaceholder !== undefined) {
    correctedPlaceholder = correctedPlaceholder
      .replace("(first name)", firstName)
      .replace("(last name)", lastName);
  }

  return (
    <Card className={"friends-family-card"}>
      <h1>
        Hi {inviteeFirstName}, {firstName} {lastName} invited you to share some
        thoughts
      </h1>
      <p>
        {question
          .replace("(first name)", firstName)
          .replace("(last name)", lastName)}
      </p>
      <TextAreaInput
        {...props.textAreaInputProps}
        placeholder={correctedPlaceholder}
        onChange={(value) => {
          if (stepAnswers[stepNumber] !== undefined) {
            updateStepAnswer(stepNumber, value);
          } else {
            addAnswer(value);
          }
        }}
        value={stepAnswers[stepNumber]}
      />
      <Button
        disabled={!stepAnswers[stepNumber]}
        text={buttonText}
        onClick={() => {
          onSubmit();
        }}
      />
      {canGoBack && (
        <>
          <Spacer size={3} />
          <Button
            text={"Back"}
            onClick={() => {
              goBackFn();
            }}
          />
        </>
      )}
    </Card>
  );
};

const MultiSelectStep = (props: multiSelectStepType) => {
  const {
    currentStepData,
    buttonText,
    onSubmit,
    firstName,
    lastName,
    inviteeFirstName,
    inviteeLastName,
    stepNumber,
    stepAnswers,
    updateStepAnswer,
    addAnswer,
    canGoBack,
    goBackFn,
  } = props;
  const { question } = props.currentStepData;
  const { selectableAnswers = [] } = currentStepData;
  const [selections, setSelections] = useState<string[]>([]);
  useEffect(() => {
    stepAnswers[stepNumber] && setSelections(stepAnswers[stepNumber]);
  }, []);
  useEffect(() => {
    if (stepAnswers[stepNumber] !== undefined) {
      updateStepAnswer(stepNumber, selections);
    } else {
      addAnswer(selections);
    }
  }, [selections]);
  return (
    <Card>
      <h1>
        Hi {inviteeFirstName}, {firstName} {lastName} invited you to share some
        thoughts
      </h1>
      <p>
        {question
          .replace("(first name)", firstName)
          .replace("(last name)", lastName)}
      </p>
      <small>
        {selections.length} / {currentStepData.maxSelectableAnswers} selected
      </small>
      <Spacer size={3} />
      <MultiSelect
        options={selectableAnswers}
        selections={selections}
        onOptionClick={(value: string) => {
          if (selections.includes(value)) {
            setSelections(
              [...selections].filter((val) => {
                return val !== value;
              })
            );
          } else {
            if (
              currentStepData.maxSelectableAnswers &&
              selections.length < currentStepData.maxSelectableAnswers
            ) {
              setSelections([...selections, value]);
            }
          }
        }}
      />
      <Button
        disabled={selections.length !== currentStepData.maxSelectableAnswers}
        text={buttonText}
        onClick={() => {
          onSubmit();
        }}
      />
      {canGoBack && (
        <>
          <Spacer size={3} />
          <Button
            text={"Back"}
            onClick={() => {
              goBackFn();
            }}
          />
        </>
      )}
    </Card>
  );
};

const FriendsFamilyPage = () => {
  const {
    userInteractionId,
    hash,
    firstName = "",
    lastName = "",
  } = useParams();
  const {
    currentStepData,
    advanceStep,
    goBackAStep,
    currentStepNumber,
    maxStepNumber,
  } = useFriendsFamilySteps();
  const {
    stepAnswers,
    addAnswer,
    updateStepAnswer,
    submitAnswers,
    setLastName,
    setFirstName,
  } = useStepAnswers();
  const [status, setStatus] = useState<{
    valid: boolean;
    answered: boolean;
    loaded: boolean;
  }>({ valid: false, answered: false, loaded: false });
  const [inviteeFirstName, setInviteeFirstName] = useState("");
  const [inviteeLastName, setInviteeLastName] = useState("");

  useEffect(() => {
    const checkInvite = async () => {
      const data: {
        valid: boolean;
        answered: boolean;
        firstName: string;
        lastName: string;
      } = await checkIfInviteLinkIsValid(userInteractionId + "", hash + "");
      setStatus({
        ...data,
        loaded: true,
      });
      setInviteeFirstName(data.firstName);
      setInviteeLastName(data.lastName);
    };
    checkInvite();
    setFirstName(firstName);
    setLastName(lastName);
  }, []);

  const submitFn = async () => {
    if (currentStepNumber === maxStepNumber - 1) {
      await submitAnswers(userInteractionId, hash);
      advanceStep();
    } else {
      advanceStep();
    }
  };

  return (
    <div className="container">
      <LogoHorizontal width={150} color={colors.primary[4]} />
      {status.loaded && (
        <>
          {status.valid && !status.answered && (
            <>
              {currentStepNumber === maxStepNumber ? (
                <div className={"page"}>
                  <DownloadPage
                    titleOverride={
                      "Thank you for your input. To start your own journey, download the Nota app."
                    }
                  />
                </div>
              ) : (
                <div className="page assessment-step-page">
                  {currentStepData && currentStepData.type === "input" && (
                    <TextAreaStep
                      inviteeFirstName={inviteeFirstName}
                      firstName={firstName}
                      inviteeLastName={inviteeLastName}
                      lastName={lastName}
                      stepData={currentStepData}
                      updateStepAnswer={updateStepAnswer}
                      addAnswer={addAnswer}
                      stepNumber={currentStepNumber}
                      stepAnswers={stepAnswers}
                      onSubmit={submitFn}
                      textAreaInputProps={{
                        autoFocus: true,
                        placeholder: currentStepData.placeholder || undefined,
                        maxLength: 500,
                      }}
                      buttonText={
                        currentStepNumber < maxStepNumber - 1
                          ? "Next"
                          : "Submit"
                      }
                      canGoBack={currentStepNumber > 0}
                      goBackFn={goBackAStep}
                    />
                  )}
                  {currentStepData && currentStepData.type === "select" && (
                    <MultiSelectStep
                      firstName={firstName}
                      inviteeFirstName={inviteeFirstName}
                      inviteeLastName={inviteeLastName}
                      lastName={lastName}
                      currentStepData={currentStepData}
                      updateStepAnswer={updateStepAnswer}
                      addAnswer={addAnswer}
                      stepNumber={currentStepNumber}
                      stepAnswers={stepAnswers}
                      buttonText={
                        currentStepNumber < maxStepNumber - 1
                          ? "Next"
                          : "Submit"
                      }
                      onSubmit={submitFn}
                      canGoBack={currentStepNumber > 0}
                      goBackFn={goBackAStep}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {!status.valid && <InvalidLinkCard />}
          {status.valid && status.answered && <AlreadyAnsweredCard />}
        </>
      )}
    </div>
  );
};

export default FriendsFamilyPage;
