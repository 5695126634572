import "./multi-select-option.scss";

export type MultiSelectOptionType = {
  selected: boolean;
  label: string;
  value: string;
  onClick: any;
  disabled: boolean;
};
export default function MultiSelectOption(props: MultiSelectOptionType) {
  const { selected, label, disabled, onClick } = props;
  return (
    <h6
      className={`multi-step-option ${selected && "selected"} ${
        disabled && "disabled"
      }`}
      onClick={onClick}
    >
      {label}
    </h6>
  );
}
