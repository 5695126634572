import "./appStoreLink.scss";
import downloadIOS from "./download-on-app-store.png";
import downloadAndroid from "./download-on-google-play.png";
import {
  DownloadFromAppStoreButton,
  DownloadFromGooglePlayButton,
} from "../library";

const aspectRatio = 300 / 80;

type AppStoreLinkProps = {
  type: "ios" | "android";
  width?: number;
};
const AppStoreLink = ({ type, width = 150 }: AppStoreLinkProps) => {
  const appStoreUrl =
    type === "android"
      ? "https://play.google.com/store/apps/details?id=com.unitelife.mynota"
      : "https://apps.apple.com/us/app/my-nota/id1630818598";
  return (
    <div
      className="app-store-link"
      onClick={() => window.open(appStoreUrl, "_blank", "noopener,noreferrer")}
      style={{ width, height: width / aspectRatio }}
    >
      {type === "ios" ? (
        <DownloadFromAppStoreButton />
      ) : (
        <DownloadFromGooglePlayButton />
      )}
      {/*<img src={type === "ios" ? downloadIOS : downloadAndroid} />*/}
    </div>
  );
};

export default AppStoreLink;
