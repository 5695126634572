import React, { CSSProperties } from "react";
import "./button.scss";
import Spacer from "../spacer";
type ButtonProps = {
  type?: "primary" | "secondary" | "text";
  disabled?: boolean;
  text: string;
  onClick?: () => void;
  style?: CSSProperties;
  icon?: any;
  className?: string;
};
const Button = (props: ButtonProps) => {
  const {
    type = "primary",
    disabled = false,
    text,
    onClick,
    style,
    icon,
    className = "",
  } = props;
  return (
    <div
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      style={style}
      className={`button ${type === "secondary" || ("text" && type)} ${
        disabled && "disabled"
      } ${className}`}
    >
      {icon && icon}
      {icon && <Spacer direction={"horizontal"} size={3} />}
      {text}
    </div>
  );
};

export default Button;
