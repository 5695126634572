const { REACT_APP_API_URL } = process.env;

const urls = {
  local: "http://localhost:8080",
  staging: "https://api-staging.mynota.io",
  production: "https://api.mynota.io",
};

type ApiUrlType = "local" | "staging" | "production";
const getUrl = (type: ApiUrlType) => {
  if (process.env.NODE_ENV === "production") {
    return REACT_APP_API_URL;
  }

  return urls[type];
};

const getApiUrl = (type: ApiUrlType) => {
  return `${getUrl(type)}/api`;
};

export default getApiUrl;
