import AppStoreLink from "../../components/appStoreLink/appStoreLink";
import "./aboutPage.scss";
import { useEffect, useState } from "react";

const AboutPage = () => {
  const [deviceOS, setDeviceOS] = useState("other");
  const getDeviceOS = () => {
    const isIphone = navigator.userAgent.indexOf("iPhone") != -1;
    const isIpod = navigator.userAgent.indexOf("iPod") != -1;
    const isIpad = navigator.userAgent.indexOf("iPad") != -1;
    const isAndroid = navigator.userAgent.indexOf("Android") != -1;
    if (isIphone || isIpod || isIpad) {
      setDeviceOS("ios");
    } else if (isAndroid) {
      setDeviceOS("android");
    }
  };
  useEffect(() => {
    getDeviceOS();
  }, []);

  return (
    <div className="about-page">
      <div className="about-page-content">
        <h1>What is Nota?</h1>
        <p>
          NOTA is a Latin word that means a unique signature or mark. You, like
          a signature, are unique and important. You have a purpose and your
          life will leave a mark on this world and on the people in your life.
          You're invited on a journey that'll uncover who you are, why you're
          here, and how you can live your life accordingly.
        </p>
        <p>
          Within Nota, we’ll answer questions like: Who am I? What was I created
          for? What makes me special? We’ll take a deep dive into the concepts
          of Identity and Purpose and we’ll give you the tools to make those
          concepts matter to the rest of the world. You were made to impact your
          community in a positive way, you were made to leave a mark on the
          world for good. You were created on purpose for a purpose, you’re not
          a mistake or an accident.
        </p>
        <p>Download Nota today if you’re ready for change.</p>
        <div className="store-links">
          <AppStoreLink type="ios" />
          <AppStoreLink type="android" />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
