import RequestClient from "./helpers/requestClient";
import { createOnboardingAssessmentDTO } from "./transformation/createOnboardingAssessmentDTO";
import { AnswerMap } from "../hooks/useWebAssessment/useAnswers";
import { AssessmentResults } from "../hooks/useWebAssessment/useAssessmentResults";
import { ResourceCollection } from "../types/resourceCollection.types";

var client: RequestClient = RequestClient.initClient();

//#region assessment endpoints
export const getOnboardingAssessments = async () => {
  const result = await client.get<ResourceCollection[]>(
    `/onboarding/assessments`
  );
  return result;
};

export const submitOnboardingAssessment = async (
  name: string,
  email: string,
  password: string,
  answers: AnswerMap,
  results: AssessmentResults
) => {
  const submissionData = createOnboardingAssessmentDTO({
    name,
    email,
    password,
    answers,
    results,
  });
  return client
    .post<{ error?: string }>("/onboarding/web", submissionData)
    .catch(() => ({ error: "An unexpected error occurred " }));
};

export const checkEmailExists = async (email: string) => {
  return client.get<any>("/onboarding/check-email", { email });
};

export const sendAppLink = async (phone: string) => {
  return client.post<any>("/onboarding/send-app-link", { phone });
};

export const sendInvitedAnswers = async (
  hash: string,
  id: string,
  data: any
) => {
  return client.patch<any>(
    `userInteractions/friendsfamily/${id}/${encodeURIComponent(hash)}`,
    data
  );
};

export const checkIfInviteLinkIsValid = async (id: string, hash: string) => {
  return client.get<any>(
    `userInteractions/friendsfamily/verify/${id}/${encodeURIComponent(hash)}`,
    {}
  );
};

export const resetPassword = async (password: string, token: string) => {
  return client.post<any>("/onboarding/password-reset", { password }, token);
};

export const submitEmailToNotaReimaginedMailingList = async (email: string) => {
  return client.post<any>("/email/subscribe-nota-reimagined/app", { email });
}
//#endregion
