import { Outlet } from "react-router-dom";
import Footer from "./components/footer/footer";
import LanguageSelect from "./components/languageSelect/languageSelect";
import NavBar from "./components/navBar/navBar";
import "./layout.scss";

const Layout = () => {
  return (
    <div className="layout">
      <NavBar />
      <div className="page">
        <Outlet />
        {/*<LanguageSelect />*/}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
