import React, { PropsWithChildren } from "react";
import "./card.scss";

type CardProps = {
  className?: string;
};
const Card = (props: PropsWithChildren<CardProps>) => {
  const { children, className = "" } = props;
  return <div className={`card ${className}`}>{children}</div>;
};

export default Card;
