import React, {
  CSSProperties,
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import "./textAreaInput.scss";

export type TextAreaInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: () => any;
  style?: CSSProperties;
  autoFocus?: boolean;
  className?: string;
  onKeyPressEnter?: () => any;
  capFirstLetter?: boolean;
  maxLength?: number | undefined;
  validationPopup?: any;
};
const TextAreaInput = (props: TextAreaInputProps) => {
  const {
    value = "",
    onChange,
    placeholder = "",
    disabled = false,
    style,
    autoFocus = false,
    className = "",
    onKeyPressEnter = () => {},
    capFirstLetter = false,
    maxLength = undefined,
    validationPopup,
    onBlur,
  } = props;
  const [internalValue, setInternalValue] = useState(value);
  const [displayValidation, setDisplayValidation] = useState(false);

  const handleKeyPressEnter = (
    event: SyntheticEvent<HTMLTextAreaElement> & { keyCode: number }
  ) => {
    if (event.keyCode === 13) {
      onKeyPressEnter();
    }
  };
  useEffect(() => {
    if (onChange) {
      onChange(internalValue);
    }
  }, [internalValue]);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const initialValue = event.target.value as string;
    let newValue = capFirstLetter
      ? `${initialValue.charAt(0).toUpperCase()}${initialValue.slice(1)}`
      : initialValue;
    setInternalValue(newValue);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        position: "relative",
        ...style,
      }}
    >
      {validationPopup && displayValidation && validationPopup}
      <textarea
        autoFocus={autoFocus}
        onFocus={() => {
          if (validationPopup !== undefined) {
            setDisplayValidation(true);
          }
        }}
        onBlur={() => {
          if (validationPopup !== undefined) {
            setDisplayValidation(false);
          }
        }}
        className={`lib-text-area-input ${className}`}
        value={internalValue}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        style={style}
        onKeyUp={handleKeyPressEnter}
        maxLength={maxLength}
        onBlurCapture={onBlur}
      />
    </div>
  );
};

export default TextAreaInput;
