import { friendsFamilyStepType } from "./useFriendsFamilySteps";

const steps: friendsFamilyStepType[] = [
  {
    type: "input",
    question: "What is something you have seen (first name) excel at?",
    placeholder: "(first name) excels at example goes here",
  },
  {
    type: "input",
    question:
      "If you were to share something at (first name)'s funeral, what would you say?",
    placeholder: "(first name) was example goes here",
  },
  {
    type: "select",
    question: "Select the top 5 traits that define (first name).",
    selectableAnswers: [
      "Bold",
      "Loyal",
      "Peaceful",
      "Friendly",
      "Charismatic",
      "Adventurous",
      "Compassionate",
      "Courageous",
      "Creative",
      "Energetic",
      "Honest",
      "Daring",
      "Confident",
      "Good Sense of Humor",
      "Ambitious",
      "Humble",
      "Empathetic",
      "Charming",
      "Intelligent",
      "Playful",
      "Hospitable",
      "Leader",
      "Teacher",
      "Kind",
      "Tenacious",
      "Strong",
      "Organized",
      "Joyful",
    ],
    maxSelectableAnswers: 5,
  },
];

export default steps;
