import MultiSelectOption from "../../multi-select-option";
import { useEffect, useState } from "react";
import "./multi-select.scss";

export type MultiSelectType = {
  selections: string[];
  options: string[];
  onOptionClick: any;
};
export default function MultiSelect(props: MultiSelectType) {
  const { options, selections, onOptionClick } = props;

  return (
    <div className={"multi-select"}>
      {options.length &&
        options.map((option) => {
          return (
            <MultiSelectOption
              key={option}
              selected={selections.includes(option)}
              disabled={selections.length > 4 && !selections.includes(option)}
              label={option}
              value={option}
              onClick={() => {
                onOptionClick(option);
              }}
            />
          );
        })}
    </div>
  );
}
