import { useEffect, useState } from "react";
import steps from "./stepsJSON";

export type friendsFamilyStepType = {
  type: "select" | "input";
  question: string;
  placeholder?: string;
  selectableAnswers?: string[];
  maxSelectableAnswers?: number;
};

export default function useFriendsFamilySteps() {
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [stepData, setStepData] = useState<friendsFamilyStepType>(steps[0]);

  useEffect(() => {
    setStepData(steps[currentStepNumber]);
  }, [currentStepNumber]);

  const advanceStep = () => {
    if (currentStepNumber < steps.length) {
      setCurrentStepNumber(currentStepNumber + 1);
    }
  };

  const goBackAStep = () => {
    if (currentStepNumber > 0) {
      setCurrentStepNumber(currentStepNumber - 1);
    }
  };

  const goToAStep = (newStepNumber: number) => {
    setCurrentStepNumber(newStepNumber);
  };

  return {
    currentStepData: stepData,
    advanceStep,
    goBackAStep,
    goToAStep,
    currentStepNumber,
    maxStepNumber: steps.length,
  };
}
