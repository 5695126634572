import { useEffect, useState } from "react";
import { friendsFamilyStepType } from "./useFriendsFamilySteps";
import { useNavigate } from "react-router-dom";
import { sendInvitedAnswers } from "../../api/apiClient";
import steps from "./stepsJSON";

export type stepAnswerType =
  | string
  | string[]
  | { question: string; answer: string | string[] };

export default function useStepAnswers() {
  const navigate = useNavigate();
  const [stepAnswers, setStepAnswers] = useState<stepAnswerType[]>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const addAnswer = (data: stepAnswerType) => {
    setStepAnswers([...stepAnswers, data]);
  };

  const submitAnswers = async (
    id: string | undefined,
    hash: string | undefined
  ) => {
    if (hash && id) {
      const answersWithQuestion: {
        question: string;
        answer: stepAnswerType;
      }[] = [];
      stepAnswers.map((answer: stepAnswerType, index: number) => {
        const fixedQuestion = steps[index].question.replace(
          "(first name)",
          firstName
        );
        answersWithQuestion.push({ question: fixedQuestion, answer });
      });
      console.log(answersWithQuestion);
      await sendInvitedAnswers(hash, id, answersWithQuestion);
    }

    //navigate("/download");
  };

  const updateStepAnswer = (stepNumber: number, data: stepAnswerType) => {
    const newStepAnswers = [...stepAnswers];
    newStepAnswers[stepNumber] = data;
    setStepAnswers(newStepAnswers);
  };

  return {
    stepAnswers,
    addAnswer,
    updateStepAnswer,
    submitAnswers,
    setFirstName,
    setLastName,
  };
}
