import * as React from "react";
import { SVGProps } from "react";
import { MulticolorProps } from "./multicolor";

const MulticolorListIcon = (props: MulticolorProps) => {
  const { width = props.height || 80, height = props.width || 80 } = props;
  const { colors = ["black", "grey"] } = props;
  return (
    <svg
      width={80}
      height={80}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50 3.334H23.333A16.667 16.667 0 0 0 6.667 20.001v40a16.666 16.666 0 0 0 16.666 16.666h17.7l-4.7-4.633a10 10 0 1 1 14-14.267l1.567 1.534L63 48.434a9.533 9.533 0 0 1 3.667-2.267V20.001A16.668 16.668 0 0 0 50 3.334ZM26.667 56.667h-3.334a3.333 3.333 0 0 1 0-6.666h3.334a3.333 3.333 0 1 1 0 6.666ZM50 43.334H23.333a3.333 3.333 0 0 1 0-6.667H50a3.333 3.333 0 0 1 0 6.667Zm0-13.333H23.333a3.333 3.333 0 0 1 0-6.667H50a3.333 3.333 0 0 1 0 6.667Z"
        fill={colors[0]}
      />
      <path
        d="M49.384 75.319c.621.615 1.46.963 2.334.966a3.334 3.334 0 0 0 2.333-.8l18.1-17.766a3.335 3.335 0 1 0-4.667-4.767L51.718 68.285l-6.234-6.133a3.334 3.334 0 0 0-4.666 4.733l8.566 8.434Z"
        fill={colors[1]}
      />
    </svg>
  );
};

export default MulticolorListIcon;
