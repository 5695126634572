import React, { useState } from "react";
import { LogoHorizontal } from "../../components/library/logos";
import "./notaReimaginedPage.scss";
import MobileHeroPhone from "../../assets/nota-reimagined/mobile-hero-phone@2x.png";
import FeatureExploration from "../../assets/nota-reimagined/exploration@2x.png";
import FeatureGuides from "../../assets/nota-reimagined/guides@2x.png";
import FeatureAudioMode from "../../assets/nota-reimagined/audio-mode@2x.png";
import FeatureTrails from "../../assets/nota-reimagined/trails@2x.png";
import FeatureBuiltAroundYou from "../../assets/nota-reimagined/built-around-you@2x.png";
import DesktopPhone1 from "../../assets/nota-reimagined/desktop-phone-1.svg";
import DesktopPhone2 from "../../assets/nota-reimagined/desktop-phone-2.svg";
import DesktopPhone3 from "../../assets/nota-reimagined/desktop-phone-3.svg";
import HeroPolygon from "../../assets/nota-reimagined/hero-polygon.svg";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import AppStoreLink from "../../components/appStoreLink/appStoreLink";
import Loader from "../../components/loader/loader";
import { CustomIconEnvelope } from "../../components/library/icons";
import { submitEmailToNotaReimaginedMailingList } from "../../api/apiClient";

const NotaReimagined = () => {
  const posUp = useScrollPosition(-0.1);

  const [submitting, setSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState<
    "success" | "failed" | null
  >(null);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);
    const { email } = Object.fromEntries(new FormData(event.target));
    const res = await submitEmailToNotaReimaginedMailingList(email as string);
    setSubmitting(false);
    console.log(res);

    if (res.success) {
      setSubmissionResult("success");
    } else {
      setSubmissionResult("failed");
    }
  };

  return (
    <div className="nota-reimagined-page">
      <section className="hero-section">
        <div id="mobileFloater1" className="floater mobile red"></div>
        <div className="hero-white-triangle"></div>
        <div className="container">
          <div className="logo-container">
            <LogoHorizontal color={"white"} />
          </div>
          <div className="hero-content">
            <div className="hero-text">
              <h1>
                <p>Welcome to Nota</p>
                Live Life to the Fullest.
              </h1>
              <p>Download Latest Version</p>
              <div className="button-wrap">
                <AppStoreLink type="ios" />
                <AppStoreLink type="android" />
              </div>
            </div>
            <div className="hero-images">
              <div className="mobile-hero-image">
                <img src={MobileHeroPhone} />
              </div>
              <div className="desktop-hero-image">
                <img
                  className="bg-polygon floatingVertical"
                  src={HeroPolygon}
                />
                <div className="phones">
                  <img src={DesktopPhone1} />
                  <img src={DesktopPhone2} />
                  <img src={DesktopPhone3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="preview-section" style={{ marginTop: posUp - 120 }}>
        <div id="mobileFloater2" className="floater mobile red"></div>
        <div id="mobileFloater3" className="floater mobile blue"></div>
        <div id="mobileFloater4" className="floater mobile yellow"></div>
        <div
          id="floater1"
          className="floater desktop medium red floatingHorizontal"
        ></div>
        <div
          id="floater2"
          className="floater desktop small yellow floatingVertical"
        ></div>
        <div id="floater3" className="floater desktop large red"></div>
        <div id="floater4" className="floater desktop large yellow"></div>
        <div
          id="floater5"
          className="floater desktop medium red floatingHorizontal"
        ></div>
        <div
          id="floater6"
          className="floater desktop small yellow floatingVertical"
        ></div>
        <div id="floater7" className="floater desktop large yellow"></div>
        <div id="floater8" className="floater desktop medium red"></div>
        <div id="floater9" className="floater desktop medium red"></div>
        <div className="preview-header">
          <p>Coming Soon!</p>
          <h2>Nota, Reimagined.</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              required
            ></input>
            <button
              type="submit"
              disabled={submitting || submissionResult === "success"}
            >
              {submitting ? (
                <div style={{ display: "flex" }}>
                  <Loader />
                </div>
              ) : (
                <span className="submit-button" style={{ display: "flex" }}>
                  Join Waitlist&nbsp;
                  <CustomIconEnvelope size={10} />
                </span>
              )}
            </button>
          </form>
          {submissionResult === "success" && (
            <p style={{ margin: "1rem 0 0", fontSize: "1rem" }}>
              Submission success! Thanks for joining the waitlist!
            </p>
          )}
          {submissionResult === "failed" && (
            <p style={{ margin: "1rem 0 0", fontSize: "1rem" }}>
              There was a problem submitting your email.
            </p>
          )}
        </div>
        <p className="get-excited-for">Get Excited For:</p>
        <div className="feature-grid">
          <div>
            <div className="feature-image dark">
              <img src={FeatureExploration} />
            </div>
            <div className="feature-text">
              <p className="title">Free Exploration</p>
              <p className="description">
                Like exploring a new forest, path, or city, Nota has been
                reworked to empower you to choose where you go next, and when.
              </p>
            </div>
          </div>
          <div>
            <div className="feature-image light">
              <img src={FeatureGuides} />
            </div>
            <div className="feature-text">
              <p className="title">Guides</p>
              <p className="description">
                Dialogue with an AI-powered guide, there for your questions and
                ready to help you grow.
              </p>
            </div>
          </div>
          <div>
            <div className="feature-image light">
              <img src={FeatureAudioMode} />
            </div>
            <div className="feature-text">
              <p className="title">Audio Mode</p>
              <p className="description">
                Nota is there for you, even in the car or on a dirt trail.
                Enable audio mode to listen to content no matter where you are.
              </p>
            </div>
          </div>
          <div>
            <div className="feature-image light">
              <img src={FeatureTrails} />
            </div>
            <div className="feature-text">
              <p className="title">Trails</p>
              <p className="description">
                These hand-made paths guide you through pivotal topics in
                personal growth, the Bible, and relationship with God.
              </p>
            </div>
          </div>
          <div>
            <div className="feature-image dark">
              <img src={FeatureBuiltAroundYou} />
            </div>
            <div className="feature-text">
              <p className="title">Built Around You</p>
              <p className="description">
                You know your challenges and goals, and Nota is designed to
                orient around those, creating a truly personal experience.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotaReimagined;
