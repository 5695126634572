import getApiUrl from "./getApiUrl";

type HttpRequestParams = {
  [key: string]: any;
};
export default class RequestClient {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  static initClient() {
    const apiUrl = getApiUrl("local");
    return new RequestClient(apiUrl);
  }

  async get<T>(path: string, params?: HttpRequestParams) {
    let endpoint = normalizeUrl(this.apiUrl, path);
    if (params) {
      endpoint += buildQueryString(params);
    }
    const response = await fetch(endpoint, {
      headers: {},
    });
    return parseResponse(response) as Promise<T>;
  }

  async post<T>(path: string, params?: HttpRequestParams, authToken?: string) {
    let endpoint = normalizeUrl(this.apiUrl, path);
    console.log(endpoint);
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...buildAuthHeader(authToken),
      },
      body: JSON.stringify(params),
    });
    return parseResponse(response) as Promise<T>;
  }

  async put<T>(path: string, params?: HttpRequestParams) {
    let endpoint = normalizeUrl(this.apiUrl, path);
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    return parseResponse(response) as Promise<T>;
  }

  async delete<T>(path: string, params?: HttpRequestParams) {
    let endpoint = normalizeUrl(this.apiUrl, path);
    if (params) {
      endpoint += buildQueryString(params);
    }
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {},
    });
    return parseResponse(response) as Promise<T>;
  }

  async patch<T>(path: string, params?: HttpRequestParams) {
    let endpoint = normalizeUrl(this.apiUrl, path);
    const response = await fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    return parseResponse(response) as Promise<T>;
  }
}

const buildQueryString = (params: HttpRequestParams) => {
  let queryParams = Object.keys(params).map((key) => `${key}=${params[key]}`);
  return `?${queryParams.join("&")}`;
};

const buildAuthHeader = (token?: string) => {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
};

const normalizeUrl = (url: string, path: string) => {
  if (url[url.length - 1] === "/") {
    url = url.slice(0, -1);
  }
  if (path[0] === "/") {
    path = path.substring(1);
  }

  return `${url}/${path}`;
};

const parseResponse = async (response: Response) => {
  return response.json().catch(() => null);
};
